import { styled } from '@mui/system'
import Icon from '../../atoms/icon'
import { SwiperSlide } from 'swiper/react'

const shouldForwardProp = (prop) => !['action', 'aboveTheFold'].includes(prop)

const Container = styled('div', { shouldForwardProp })`
	position: relative;
	--swiper-navigation-size: 48px;
	height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
`

const NavButton = styled(Icon, { shouldForwardProp })`
	color: ${({ theme }) => `var(--swiper-button-color, ${theme.palette.common.white})`};
	font-size: ${({ theme }) => theme.typography.pxToRem(40)};
	${({ action }) => (action === 'prev' ? 'left: 0;' : 'right: 0;')}

	&.swiper-button-disabled {
		pointer-events: auto;
	}
	&.swiper-button-prev {
		left: 10px;
		right: auto;
	}
	&.swiper-button-next {
		right: 10px;
		left: auto;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		/* background-color: ${({ theme }) => `var(--swiper-button-bg-color, ${theme.palette.common.white})`}; */
		/* border: 1px solid ${({ theme }) => `var(--swiper-button-border-color, ${theme.palette.grey.light})`}; */
		border-radius: var(--swiper-button-border-radius, 50%);
		color: ${({ theme }) => `var(--swiper-button-color, ${theme.palette.primary.main})`};
		background: rgba(255, 255, 255, 0.9);
		padding: 7px;
	}

	&.swiper-button-lock {
		display: none;
	}
`

const PaginationStyled = styled('div')`
	.swiper-pagination-horizontal {
		bottom: 6px;
		width: 90%;
		left: 50%;
		transform: translateX(-50%);
		${({ theme }) => theme.breakpoints.up('md')} {
			width: 100%;
			left: 0;
			transform: none;
		}
	}

	&.swiper-pagination-bullets {
		line-height: 0;
	}

	.swiper-pagination-bullet {
		background-color: transparent;
		height: auto;
		margin: ${({ theme }) => theme.spacing(0, 2)};
		opacity: 1;
		padding: ${({ theme }) => theme.spacing(0.5)};
		width: auto;
		&:before {
			background-color: ${({ theme }) => `var(--swiper-pagination-bullet-color, ${theme.palette.grey.light})`};
			border-radius: 50%;
			content: '';
			display: block;
			height: ${({ theme }) => theme.spacing(1)};
			width: ${({ theme }) => theme.spacing(1)};
		}
		&-active {
			cursor: default;
			&:before {
				background-color: ${({ theme }) => `var(--swiper-pagination-bullet-active-color, ${theme.palette.grey.main})`};
			}
		}
	}

	&.swiper-pagination-lock {
		display: none;
	}
`

const SwiperSlideStyled = styled(SwiperSlide, { shouldForwardProp })`
	display: ${({ aboveTheFold }) => (aboveTheFold ? 'flex' : undefined)};
`

const ScrollbarStyled = styled('div')`
	/* Edit swiper variables in css-baseline.js */
	&.swiper-scrollbar {
		background: ${({ theme }) => `var(--swiper-scrollbar-bg-color, ${theme.palette.grey.lighter})`};
		width: calc(100% - (var(--swiper-scrollbar-sides-offset, 1%) * 2));
		left: var(--swiper-scrollbar-sides-offset, 1%);
		right: var(--swiper-scrollbar-sides-offset, 1%);
		bottom: var(--swiper-scrollbar-bottom, 0px);
		z-index: 1;
		.swiper-scrollbar-drag {
			background: ${({ theme }) => `var(--swiper-scrollbar-drag-bg-color, ${theme.palette.grey.main})`};
			border-radius: ${({ theme }) => `var(--swiper-scrollbar-border-radius, ${theme.shape.borderRadius}px)`};
			cursor: grab;
		}
	}
`

export { Container, NavButton, PaginationStyled, SwiperSlideStyled, ScrollbarStyled }
